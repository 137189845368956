<template>
  <div>
    <LazyAppMobileAppDownloadBanner
      v-if="showBanner"
      @banner-dismissed="dismissClicked"
    />
    <LazyAppMasquerade v-if="isMasquerading" />
    <AppHeader v-if="!meta?.hideHeader" />
    <slot />
    <template v-if="!meta?.hideFooter">
      <AppFooter :is-sticky="isRvSearchPage" />
    </template>
  </div>
</template>

<script setup lang="ts">
usePartytown()

const { meta } = useRoute()
const { isMasquerading } = useMasquerade()
const { showAppBanner } = useAppBanner()
const { isRvSearchPage } = useBaseName()

const showBanner = ref(showAppBanner.value)
function dismissClicked() {
  showBanner.value = false
}
</script>
